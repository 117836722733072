import React from "react";
import Breadcrumbs from "./Breadcrumbs";
import { Container, Row, Col } from "react-bootstrap";

const Banner = () => {
    let pageName = window.location.pathname.split('/').filter(pagename => pagename!=="");
    const new_pageName = pageName.toString().replace(/-/g, ' ');
    return(
        <div className="page_banner" style={{ backgroundImage: "url(" + "./assets/banner.svg" + ")"  }}>
            <Container>
                <Row>
                    <Col md={12}>
                        <Breadcrumbs />
                        <h2>{ new_pageName }</h2>
                    </Col>
                </Row>
            </Container>  
        </div>
    );
}
export default Banner;