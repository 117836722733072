import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Sytles from '../css/processes.module.css';


const Processes = () => {
    return(
        <div className={ Sytles.processes}>
            <Container>
                <Row>
                    <Col md={12}>
                        <h2 className="white-color uppercase">Our PROCESS</h2>
                    </Col>
                </Row>
                <div className="">
                    <Row>
                        <Col md={4}>
                            <div className={ Sytles.process }>
                                <img src="./assets/process4.svg" alt="" />
                                <h4 className="white-color uppercase">Innovative Design Solutions</h4>
                                <p>
                                Leveraging engineering, artistic, and digital skills, our designers craft concept products that meet client demands while adhering to safety policies. Solidworks and CAD platforms ensure precise 3D designs.
                                </p>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className={ Sytles.process }>
                                <img src="./assets/process1.svg" alt="" />
                                <h4 className="white-color uppercase">Advanced 3D Scanning</h4>
                                <p>
                                Our collaboration with the top 3D scanning and modeling team in the Gulf region ensures unparalleled accuracy in mapping for product design and remodeling.
                                </p>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className={ Sytles.process }>
                                <img src="./assets/process3.svg" alt="" />
                                <h4 className="white-color uppercase">Precise 3D Modeling</h4>
                                <p>
                                Our team of industrial designers and advanced technology enables precise modeling of automotive equipment and products. We create accurate 3D models for thorough testing before production.
                                </p>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className={ Sytles.process }>
                                <img src="./assets/process2.svg" alt="" />
                                <h4 className="white-color uppercase">Cutting-Edge 3D Printing</h4>
                                <p>
                                At Micronisus, we possess the capability to transform any idea/design into reality through state-of-the-art 3D printing. This empowers us in real-time flaw identification, error correction, and material research before mass production.
                                </p>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className={ Sytles.process }>
                                <img src="./assets/process6.svg" alt="" />
                                <h4 className="white-color uppercase">Expert Reverse Engineering</h4>
                                <p>
                                Equipped with comprehensive reverse engineering capabilities, we excel at measuring and mapping the surfaces of objects to enable efficient mass production. Our expertise extends to accurately reverse engineering objects with complex shapes.
                                </p>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className={ Sytles.process }>
                                <img src="./assets/process5.svg" alt="" />
                                <h4 className="white-color uppercase">Uncompromising Quality</h4>
                                <p>
                                Quality is paramount at Micronisus. We maintain high-quality standards and procedures throughout the product development and testing processes, earning recognition for our superior products and solutions.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    );
}
export default Processes; 