import React from "react";
import { Container, Row, Col } from "react-bootstrap"
import GalleryImage from './Products/GalleryImage';
import { NavLink } from "react-router-dom"
import Banner from "./Global/Banner"
import Styles from './css/products.module.css'

const Products = () => {
    return (
        <>
            <Banner />
            <div className={ Styles.ourProducts }>
                <Container>
                    <Row>
                        <Col md={6}>
                            <div className={ Styles.productStyle}>
                                <GalleryImage thumb = '../products/product1.png' imgHeight="650" />
                                <div className={ Styles.prodContent } style={{  transform: 'translate(-70%, -50%)' }}>
                                    <h2>Fire <br />Extinguisher</h2>
                                    <p>A portable device designed to suppress and extinguish small fires that may occur in a vehicle.  It is a safety equipment specifically intended for automotive use and is crucial in case of emergency situations such as engine fires, electrical malfunctions, or other fire-related incidents.</p>
                                    <NavLink to="/fire-Extinguisher" className="btn-theme filled">Read More</NavLink>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className={ Styles.productStyle}>
                                <GalleryImage thumb = '../products/product2.png' imgHeight="310" />
                                <div className={ Styles.prodContent } style={{  transform: 'translate(-80%, -50%)' }}>
                                    <h3>Fire Extinguisher  <br />CLAMP</h3>
                                    <p>A device or mechanism used to securely hold a fire extinguisher in place within the vehicle.</p>
                                    <NavLink to="/fire-Extinguisher-clamp" className="btn-theme filled">Read More</NavLink>
                                </div>
                            </div>
                            <div className={ Styles.productStyle} style={{ marginTop: '25px' }}>
                                <GalleryImage thumb = '../products/product3.png' imgHeight="310" />
                                <div className={ Styles.prodContent } style={{  transform: 'translate(-80%, -50%)' }}>
                                    <h3>Roadside Assistance Kit</h3>
                                    <p>A collection of essential items and tools designed to assist drivers in case of unexpected breakdowns or emergencies on the road.</p>
                                    <NavLink to="/roadside-assitance-kit" className="btn-theme filled">Read More</NavLink>
                                </div>
                            </div>
                        </Col>

                        <Col md={6}>
                            <div className={ Styles.productStyle } style={{ marginTop: '25px' }}>
                                <GalleryImage thumb = '../products/product4.png' imgHeight="310" />
                                <div className={ Styles.prodContent } style={{  transform: 'translate(-80%, -50%)' }}>
                                    <h3>Car tool kit</h3>
                                    <p>A collection of essential tools and equipment specifically designed for automotive maintenance and repairs</p>
                                    <NavLink to="/car-tool-kit" className="btn-theme filled">Read More</NavLink>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className={ Styles.productStyle} style={{ marginTop: '24px' }}>
                                <GalleryImage thumb = '../products/product5.png' imgHeight="305" />
                                <div className={ Styles.prodContent } style={{  transform: 'translate(-80%, -50%)' }}>
                                    <h3>Other Products</h3>
                                    <p>Discover our extensive range of automotive products, featuring safety systems, and stylish enhancements.</p>
                                    <NavLink to="/other-products" className="btn-theme filled">Read More</NavLink>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}
export default Products;