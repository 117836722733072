import React from "react";
import GalleryImage from '../Products/GalleryImage';
import Image1  from '../../media/modern_service1.svg'
import Image2  from '../../media/modern_service2.svg'
import Styles from '../css/modernServices.module.css'


const ModernServices = () => {
    return (
    <>
    <div className={ Styles.listmodernService}>
        <div className={ Styles.modernServices }>
            <div className={ Styles.serviceImg }>
                <GalleryImage thumb={ Image1 } imgHeight="485px" />
            </div>
            <div className={ Styles.serviceContent } style={{paddingRight:'140px' }}>
                <h2>Accelerating <br />Through History</h2>
                <p>Micronisus Automotive, born from the technology-driven enterprise Micronisus Systems, represents the realization of an innovative vision that fuses technology and the automotive realm. We address the industry's demand for cost-effective and efficient solutions without compromising on quality. Our primary objective has been to provide innovative and valuable solutions to our clients.</p>
            </div>
        </div>
        <div className={ Styles.modernServices }>
            <div className={ Styles.serviceContent } style={{paddingLeft:'100px' }}>
                <h2>TIMELESS DURABILITY: <br />OUR COMMITMENT TO <br />AUTOMOTIVE EXCELLENCE</h2>
                <p>Strategically situated in Dubai, our corporate headquarters capitalize on the city's dynamic automotive market, known for its advanced and forward-thinking nature. It is within this dynamic environment that our team engages in intensive brainstorming sessions, design development, and cutting-edge research for a wide range of automotive brands. We leverage extreme climate conditions to test and optimize our solutions for superior performance and durability.</p>
            </div>
            <div className={ Styles.serviceImg }>
                <GalleryImage thumb = { Image2 } imgHeight="485px" />
            </div>
        </div>
                
        </div>
    </>
    );

}
export default ModernServices;