import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Sytles from '../css/processes.module.css';


const Assurance = () => {

    return (
        <div className={ Sytles.processes }>
            <Container>
                <Row>
                    <Col md={12}>
                        <h2 className="white-color uppercase">QUALITY ASSURANCE </h2>
                    </Col>
                </Row>
                <div className="">
                    <Row>
                        <Col md={4}>
                            <div className={ Sytles.process }>
                                <div className= { Sytles.processImg }><img src="./assets/assurance1.svg" alt="" /></div>
                                <h4 className="white-color uppercase">Quality Control Measures</h4>
                                <p>
                                To make sure that all our safety automotive products meet industry standards, quality control measures like crash testing, component testing, quality management systems, and compliance audits are used.
                                </p>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className={ Sytles.process }>
                            <div className= { Sytles.processImg }><img src="./assets/assurance2.svg" alt="" /></div>
                                <h4 className="white-color uppercase">Quality Management System</h4>
                                <p>
                                Our quality management system (QMS) for automotive safety products, which consists of routine audits, ongoing improvement projects, and a culture of excellence, reflects our commitment to quality.
                                </p>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className={ Sytles.process }>
                            <div className= { Sytles.processImg }><img src="./assets/assurance3.svg" alt="" /></div>
                                <h4 className="white-color uppercase">Product Testing</h4>
                                <p>
                                Our product testing procedures for automotive safety products meet or exceed industry standards and include evaluations of their robustness, performance, and reliability under various driving conditions.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    );

}
export default Assurance;