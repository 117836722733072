import React, { useRef, useState } from 'react';
import FsLightbox from "fslightbox-react";
import { HomeGalleryFull, HomeGallery } from "../../data/data";
import Slider from "react-slick";
import GalleryImage from '../Products/GalleryImage';
import { FaSearchPlus, FaAngleLeft, FaAngleRight } from "react-icons/fa";
import Styles from '../css/singleProduct.module.css';


const Gallery = () => {
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [lightboxIndex, setLightboxIndex] = useState(0);

    const openLightbox = (index) => {
      setLightboxIndex(index);
      setLightboxOpen(!lightboxOpen);
    };
   
    const slider = useRef(); 
    const next = () => {
        slider.current.slickNext();
    };
    const previous = () => {
        slider.current.slickPrev();
    }; 
    var settings = {
        dots: false,
        arrows: false,
        autoplay: false,
        infinite: false,
        touchMove: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
             breakpoint: 1024,
             settings: {
               slidesToShow: 2,
               slidesToScroll: 1,
               infinite: true,
               dots: true,
               touchMove: false,
             },
           },
           {
             breakpoint: 768,
             settings: {
               slidesToShow: 2,
               slidesToScroll: 1,
               touchMove: true,
             },
           },
           {
            breakpoint: 480,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              touchMove: true,
            },
          },
        ],
    };
    return(
        <>
        <div className={ Styles.galleries}>
            <h2 className='white-color uppercase'>Gallery</h2>
            <button className={ Styles.slickArrows + ' ' + Styles.prev } onClick={previous}> <FaAngleLeft/> </button>
            <Slider ref={(c) => (slider.current = c)} {...settings}>
                {HomeGallery.map((item, index) => (
                <div className={Styles.galleryItem} key={item.id}>
                    <GalleryImage thumb={ item.thumb } imgHeight="320px" imageId={item.id}  />
                    <a onClick={() => openLightbox(item.id)}><FaSearchPlus /></a>
                </div>
                ))}
            </Slider>
            <button className={ Styles.slickArrows + ' ' + Styles.next } onClick={next}> <FaAngleRight/> </button>
            <FsLightbox
              toggler={lightboxOpen}
              slide={lightboxIndex}
              sources={HomeGalleryFull}
              
            />
        </div>
        </>
    );
}
export default Gallery;