import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Styles from '../css/safety.module.css';
import { NavLink } from "react-router-dom";
import GalleryImage from '../Products/GalleryImage';
import Product1 from '../../media/safety_prod_1.svg';
import Product2 from '../../media/safety_prod_2.svg';
import Product3 from '../../media/safety_prod_3.svg';
import Product4 from '../../media/safety_prod_4.svg';

const SafetyProducts = () => {
    return (
        <>
            <div className={ Styles.safety }>
                <Container>
                    <Row>
                        <Col md={12}>
                            <div className="section-title">
                            <h2 className="white-color uppercase">Safety PRODUCTS</h2>
                            <NavLink to="/products" className="btn-theme border">View All</NavLink>
                            </div>
                        </Col>
                    </Row>
                    <div className={ Styles.safetyProds}>
                        <Row>
                            <Col sm={6} md={3}>
                                <NavLink to="/fire-Extinguisher-clamp" className={ Styles.prodImg}>
                                    <GalleryImage thumb = { Product1 } imgHeight="320px" />
                                </NavLink>
                            </Col>
                            <Col sm={6} md={3}>
                                <NavLink to="/fire-Extinguisher" className={ Styles.prodImg}>
                                <GalleryImage thumb = { Product2 }  imgHeight="320px"/>
                                </NavLink>
                            </Col>
                            <Col sm={6} md={3}>
                                <NavLink to="/roadside-assitance-kit" className={ Styles.prodImg}>
                                <GalleryImage thumb = { Product3 }  imgHeight="320px"/>
                                </NavLink>
                            </Col>
                            <Col sm={6} md={3}>
                                <NavLink to="/car-tool-kit" className={ Styles.prodImg}>
                                <GalleryImage thumb = { Product4 } imgHeight="320px" />
                                </NavLink>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>

        </>
    );
}

export default SafetyProducts;