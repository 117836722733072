import React from "react";
import Banner from "./Global/Banner";
import { blog } from "../data/data";
import {Container, Row, Col} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import Styles from '../components/css/blog.module.css';
import Sidebar from '../components/Sidebar';


const Blog = () => {
    return (
        <>
        <Banner />
        <div className="gap">
        <Container>
            <Row>
                <Col md={8}>
                    <div className="blog-lists">
                        {blog.map((item) => (
                            <div className={Styles.postCard} key={item.id}>
                                <div className={Styles.postThumb}>
                                    <img src={item.cover} alt='' />
                                </div>
                                <div className={Styles.postDetail}>
                                    <Link to={`/details/${item.id}`}>
                                    <h4>{item.title}</h4>
                                    </Link>
                                    <p>{item.desc.slice(0, 180)}...</p>
                                    <ul className={Styles.postCats}>
                                        <li>{item.category}</li>
                                        <li>{item.date}</li>
                                    </ul>
                                    <Link to={`/details/${item.id}`} className={Styles.readMore}>Read More</Link>
                                </div>
                            </div>
                        ))}
                </div>
                </Col>
                <Col md={4}>
                    <Sidebar />
                </Col>
            </Row>
        </Container>
        </div>
            
        </>
    );
}
export default Blog;