import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";  
import FsLightbox from "fslightbox-react";
import GalleryImage from '../Products/GalleryImage';
import Styles from '../css/video.module.css'

const HomeVideo = () => {
    const [toggler, setToggler] = useState(false);
    return(
        <div className="gap r_top">
            <Container>
                <Row>
                    <Col md={12}>
                        <div className={ Styles.homeVideo }>
                            <h2>Product Installation</h2>
                            <a title="" onClick={() => setToggler(!toggler)}>
                                <GalleryImage thumb='./assets/video_img.svg' imgHeight="580px" />
                            </a>
                            <FsLightbox
                                toggler={toggler}
                                sources={[
                                    './assets/product.mp4'
                                ]}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>    
        </div>
    );

}
export default HomeVideo;