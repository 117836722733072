import React, { useState, useEffect } from "react"
import { Blurhash } from "react-blurhash"

const GalleryImage = (obj) => {
    const [imageLoaded, setImageLoaded] = useState(false);
	useEffect( () => {
		const img = new Image()
		img.onload = () => {
			setImageLoaded(true)
		}
		img.src = obj.thumb
	}, [obj.thumb] )
    return (
        <>
				{ !imageLoaded ? (
				<Blurhash
					hash = 'LEHV6nWB2yk8pyo0adR*.7kCMdnj'
					width = "100%"
					height = {obj.imgHeight}
					resolutionX={32}
					resolutionY={32}
					punch={1} 
				/>
				) : (
					
					<img 
						onLoad = { () => setImageLoaded(true) }
						src = {obj.thumb} 
						alt='' 
					/>
				)}
		
        </>
    )

}
export default GalleryImage;