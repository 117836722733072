import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Banner from "./Global/Banner";
import ContactForm from '../components/AboutPage/ContactForm';
import Styles from './css/contact.module.css'

const Contact = () => {
    return (
        <>
           <Banner />
           <div className="gap">
            <Container>
                <Row>
                    <Col md={4}>
                        <div className={ Styles.contactBox  }>
                            <h4>ADDRESS</h4>
                            <p>Office# 508, Stadium Point Building, Dubai Sports City, Dubai, UAE</p>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className={ Styles.contactBox}>
                            <h4>Open HOURS</h4>
                            <ul>
                                <li>Monday - Thursday</li>
                                <li>Friday</li>
                                <li>Saturday</li>
                                <li>Sunday</li>
                                <li>9 am - 6 pm</li>
                                <li>9 am - 1 pm</li>
                                <li>Closed</li>
                                <li>Closed</li>
                            </ul>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className={ Styles.contactBox }>
                            <h4>Customer Support</h4>
                            <p>(+971) 4 566 9827</p>
                            <p>info@micronisus.ae</p>
                        </div>
                    </Col>
                    <Col md={12}>
                        <div className={ Styles.contactMap }>
                            <img src="./assets/map.svg" alt="" />
                            <div className={Styles.pointer}>
                                <div className={ Styles.addressTooltip }>
                                    <p>Office# 508, Stadium Point, Dubai Sports City, Dubai, UAE</p>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            <ContactForm />
            </div>
        </>
    );
}   
export default Contact;