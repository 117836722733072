import React, { useRef, useState } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import Banner from "../Global/Banner";
import Slider from "react-slick";
import FsLightbox from "fslightbox-react";
import GalleryImage from '../Products/GalleryImage';
import { FaSearchPlus, FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { CTKitGalleryFull, CTKitGallery, CTKitContent } from "../../data/data";
import Styles from '../css/singleProduct.module.css';

const CarToolKit = () => {
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [lightboxIndex, setLightboxIndex] = useState(0);
    const openLightbox = (index) => {
        setLightboxIndex(index);
        setLightboxOpen(!lightboxOpen);
    };

    const slider = useRef();
    const next = () => {
        slider.current.slickNext();
    };
    const previous = () => {
        slider.current.slickPrev();
    };
    var settings = {
        dots: false,
        arrows: false,
        autoplay: true,
        infinite: true,
        centerPadding: '50px',
        slidesToShow: 4,
        touchMove: false,
        slidesToScroll: 1,
        responsive: [
            {
             breakpoint: 1024,
             settings: {
               slidesToShow: 3,
               slidesToScroll: 1,
               infinite: true,
               dots: true,
             },
           },
           {
             breakpoint: 768,
             settings: {
               slidesToShow: 2,
               slidesToScroll: 1,
               touchMove: true,
             },
           },
           {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              touchMove: true,
            },
          },
        ],
    };
    return (
        <>
            <Banner />
            <Container>
                <Row className="align-center gx-5 gap">
                    <Col md={8}>
                        <div className={ Styles.singleContent}>
                            <h2>CAR TOOL KIT</h2>
                            <p>The Car Toolkit is a black foldable velcro bag which consists of basic tools that are required in the car. It consists of a nose plier, wrench 8/10, wrench 13/15, a screwdriver (+,-) and a tire gauge.
                            </p>
                            <ul>
                                <li>Size: 11cm x 16.5cm x 9cm</li>
                                <li>Weight: 600g</li>
                                <li>Color: Black</li>
                            </ul>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className={ Styles.singleContent}>
                            <GalleryImage thumb = "../products/single-car-tool-kit.svg" imgHeight="410" />
                        </div>
                    </Col>
                </Row>
                <div className={ Styles.prodCon } >
                    <Row>
                    <h2 className='white-color uppercase'>Contents</h2>
                    {CTKitContent.map((item) => (
                        <Col md={4}>
                            <div className={ Styles.conVal}>
                                <img src={item.image} alt="" />
                                <h6>{item.title}</h6>
                            </div>
                        </Col>
                    ))}
                    </Row>
                </div>

                <div className={ Styles.galleries}>
                    <h2 className='white-color uppercase'>Gallery</h2>
                    <button className={ Styles.slickArrows + ' ' + Styles.prev } onClick={previous}> <FaAngleLeft/> </button>
                    <Slider ref={(c) => (slider.current = c)} {...settings}>
                        {CTKitGallery.map((item) => (
                        <div className={Styles.galleryItem}>
                            <GalleryImage thumb = { item.image } imgHeight="320" />
                            <a onClick={() => openLightbox(item.id)}><FaSearchPlus /></a>
                        </div>
                        ))}
                    </Slider>
                    <button className={ Styles.slickArrows + ' ' + Styles.next } onClick={next}> <FaAngleRight/> </button>
                    <FsLightbox
                        toggler={lightboxOpen}
                        slide={lightboxIndex}
                        sources={CTKitGalleryFull}
                    />
                </div>

            </Container>
        </>
    );
}
export default CarToolKit;
