import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import WaterMark  from '../../media/promotors.svg'
import Styles from '../css/hero.module.css'


const Hero = () => {
    return (
    <>
        <div className="">
            <div className={ Styles.waterMark}>
                <img src={ WaterMark } alt="Automotive" />
            </div>
            <div className={ Styles.heroSect }>
                <Container>
                    <Row>
                        <Col md={5}>
                            <div className={ Styles.heroContent}>
                                <h1>we make <br />excellence</h1>
                                <p>At Micronisus, we work closely with our customers to develop automotive solutions that ignite excitement and provide a competitive edge in the market. Our products adhere to the highest safety standards, ensuring peace of mind for our valued clients.</p>
                                <NavLink to="/about" className="btn-theme filled">Learn More</NavLink>
                            </div>
                        </Col>
                        <Col md={7}>
                            <div className={ Styles.heroImage}>
                                <img src="./assets/header_video.gif" alt="" />
                                
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    </>
    );
}

export default Hero;