import React from "react";
import Banner from "./Global/Banner";
import ModernServices from "./AboutPage/ModernServices";
import FancyProcess from "./AboutPage/FancyProcess";
import Assurance from "./AboutPage/Assurance";
import Team from './AboutPage/Team';
import ContactForm from "./AboutPage/ContactForm";

const About = () => {
    return (
        <>
        <Banner />
        <div className="gap r_top">
            <ModernServices />
            <FancyProcess />
            <Assurance />
            <Team />
            <ContactForm />
        </div>
        </>
    );
}
export default About;