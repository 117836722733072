import React from "react";
import { NavLink } from "react-router-dom";

const Navbar = () => {
    return(
        <>
           <li><NavLink to="/">Home</NavLink></li>
           <li><NavLink to="/about">About</NavLink></li>
           <li>
            <NavLink to="/products">Products
           </NavLink>
                <ul>
                    <li><NavLink to="/fire-Extinguisher">Fire Extinguisher</NavLink></li>
                    <li><NavLink to="/fire-Extinguisher-clamp">Fire Extinguisher Clamp</NavLink></li>
                    <li><NavLink to="/roadside-assitance-kit">Roadside Assistance Kit</NavLink></li>
                    <li><NavLink to="/car-tool-kit">Car Tool Kit</NavLink></li>
                    <li><NavLink to="/other-products">Other Products</NavLink></li>
                </ul>
           </li>
           <li><NavLink to="/contact">Contact</NavLink></li>
           
        </>
    );
}
export default Navbar;