import React from "react";
import { Container } from "react-bootstrap";
import Hero from "./HomePage/Hero";
import AboutMicronisus from "./HomePage/AboutMicronisus";
import SafetyProducts from "./HomePage/SafetyProducts";
import Processes from "./HomePage/Processes";
import WorkingProducts from './HomePage/WorkingProducts';
import HomeVideo from './HomePage/HomeVideo';
import Gallery from './Products/Gallery';
import ContactForm from './AboutPage/ContactForm';

const Home = () => {
    return (
        <div className="gap n_top">

            <Hero />
            <AboutMicronisus />
            <SafetyProducts />
            <Processes />
            <HomeVideo />
            <WorkingProducts />
            <Container>
            
            <Gallery />
            </Container>
            <ContactForm />
        </div>
    );
}
export default Home;