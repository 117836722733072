import React from 'react';
import About from './Widgets/About';
import FooterLinks from './Widgets/FooterLinks';
import FooterProdLinks from './Widgets/FooterProdLinks';
import Subscribe from './Widgets/Subscribe';
import { Container, Row, Col } from 'react-bootstrap';


const Footer = () => {

    return(
        <>
            <footer>
                <Container>
                    <Row>
                        <Col md={3}>
                            <About />
                        </Col>
                        <Col md={3}>
                            <FooterLinks />
                        </Col>
                        <Col md={3}>
                            <FooterProdLinks />
                        </Col>
                        <Col md={3}>
                            <Subscribe />
                        </Col>
                    </Row>
                </Container>
            </footer>
        </>
    );
}
export default Footer;