import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import Styles from '../css/aboutMicronisus.module.css';

const AboutMicronisus = () => {
    return(
        <>
            <div className={Styles.aboutMic }>
                <Container>
                    <Row>
                        <Col md={12}>
                            <h2>WHAT IS MICRONISUS AUTOMOTIVE?</h2>
                            <p>Welcome to Micronisus Automotive, a leading provider of technology-driven solutions for the automotive industry. Established in 2019, we focus on developing innovative accessories and parts that push the boundaries of automotive innovation. Based in Dubai, our reputation for game-changing designs and exceptional customer support extends throughout the Middle East.Our goal is to provide our valued clients with superior products that deliver unparalleled performance, reliability, and value. Explore our range of transformative solutions and experience the difference that Micronisus can make in shaping the future of the automotive industry.</p>
                            <ul>
                                <li><a href="#query-form" className="btn-theme border" title="">Submit Your query</a></li>
                                <li><NavLink to="/about" className="btn-theme filled">Read more</NavLink></li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );   
}

export default AboutMicronisus;