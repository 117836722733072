import React from "react";
import RecentCategories from '../components/Sidebar/Category';

const Sidebar = () => {
    return(
        <aside className="blog-sidebar">
            <RecentCategories />
        </aside>
    )
}
export default Sidebar;