import React, { useRef, useState } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import Banner from "../Global/Banner";
import GalleryImage from '../Products/GalleryImage';
import Slider from "react-slick";
import FsLightbox from "fslightbox-react";
import { FaSearchPlus, FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { RAKitGalleryFull, RAKitGallery, RAKitFeatures, RAKitContent } from "../../data/data";
import Styles from '../css/singleProduct.module.css';


const Assistancekit  = () => {
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [lightboxIndex, setLightboxIndex] = useState(0);
    const openLightbox = (index) => {
        setLightboxIndex(index);
        setLightboxOpen(!lightboxOpen);
    };

    const slider = useRef();
    const next = () => {
        slider.current.slickNext();
    };
    const previous = () => {
        slider.current.slickPrev();
    };
    var settings = {
        dots: false,
        arrows: false,
        autoplay: true,
        infinite: true,
        centerPadding: '50px',
        slidesToShow: 4,
        touchMove: false,
        slidesToScroll: 1,
        responsive: [
            {
             breakpoint: 1024,
             settings: {
               slidesToShow: 3,
               slidesToScroll: 1,
               infinite: true,
               dots: true,
             },
           },
           {
             breakpoint: 768,
             settings: {
               slidesToShow: 2,
               slidesToScroll: 1,
               touchMove: true,
             },
           },
           {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              touchMove: true,
            },
          },
        ],
    };
    return (
        <>
            <Banner />
            <Container>
                <Row className="align-center gx-5 gap">
                    <Col md={8}>
                        <div className={ Styles.singleContent}>
                            <h2>ROADSIDE ASSISTANCE KIT</h2>
                            <p>Roadside Assistance Kit is one of the most useful safety equipment an automobile can contain. They are designed to provide drivers with a sense of preparedness, self-reliance, and safety while traveling. Our Roadside Assistance Kit consists of a Reflective Triangle, First Aid Kit and a Tyre Pressure Gauge, the imperatives to an automobile emergency.
                            </p>
                            <ul>
                                <li>Size: 58.5x8x5cm</li>
                                <li>Weight: 670 GSM approx</li>
                            </ul>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className={ Styles.singleContent}>
                            <GalleryImage thumb = "../products/single-roadside-assistance-kit.svg" imgHeight="410" />
                        </div>
                    </Col>
                </Row>
                <div className={ Styles.prodFeatures}>
                    <h2 className='white-color uppercase'>WHY CHOOSE OUR PRODUCT?</h2>
                    <Row>
                    {RAKitFeatures.map((item) => (
                        <Col md={4}>
                            <div className={ Styles.featBox }>
                                <img src={ item.image } alt="" />
                                <h5>{ item.title }</h5>
                                <p>{ item.desc}</p>
                            </div>
                        </Col>
                        ))}
                    </Row>
                </div>

                <div className={ Styles.prodCon } >
                    <Row>
                    <h2 className='white-color uppercase'>Contents</h2>
                    {RAKitContent.map((item) => (
                        <Col md={4}>
                            <div className={ Styles.conVal}>
                                <img src={item.image} alt="" />
                                <h6>{item.title}</h6>
                            </div>
                        </Col>
                    ))}
                    </Row>
                </div>

                <div className={ Styles.galleries}>
                    <h2 className='white-color uppercase'>Gallery</h2>
                    <button className={ Styles.slickArrows + ' ' + Styles.prev } onClick={previous}> <FaAngleLeft/> </button>
                    <Slider ref={(c) => (slider.current = c)} {...settings}>
                        {RAKitGallery.map((item) => (
                        <div className={Styles.galleryItem}>
                            <GalleryImage thumb = { item.image } imgHeight="320" />
                            <a onClick={() => openLightbox(item.id)}><FaSearchPlus /></a>
                        </div>
                        ))}
                    </Slider>
                    <button className={ Styles.slickArrows + ' ' + Styles.next } onClick={next}> <FaAngleRight/> </button>
                    <FsLightbox
                        toggler={lightboxOpen}
                        slide={lightboxIndex}
                        sources={RAKitGalleryFull}
                    />
                </div>

            </Container>
        </>
    );
}
export default Assistancekit;
