import React from "react";
import { useLocation, NavLink } from "react-router-dom";

const Breadcrumbs = () => {
    const Location = useLocation();
    
    let currentPage = '';
    const crumbs = Location.pathname.split('/')
        .filter( crumb => crumb != '' )
        .map( crumb => {
            currentPage += '/${crumb}';
            return (
                <ul className="breadcrumbs" key={crumb}>
                    <li><NavLink to='/'>Home</NavLink></li>
                    <li><span>{crumb}</span></li>
                </ul>
            );
        });
    return (
        <>{crumbs}</>
    );
}
export default Breadcrumbs;