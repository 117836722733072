export const category = [
  {
    id: 1,
    category: "store",
    title: "Stay Calm And Surf",
  },
  {
    id: 2,
    category: "repair",
    title: "Becoming a Dragonfly",
  },
  {
    id: 3,
    category: "Tuning",
    title: "There's always light at the end of the tunnel",
  },
  {
    id: 4,
    category: "Sports Car",
    title: "Stay Calm And Surf",
  },
  {
    id: 5,
    category: "Technology",
    title: "There's always light at the end of the tunnel",
  },
]
export const blog = [
  {
    id: 1,
    title: "5 Must-Have Safety Things for Your Car",
    desc: "Hello, fellow drivers! Let's have a conversation about safety. We all know that safety is no joke, but that doesn't mean we can't add a little humor to our safety gear, right? Imagine it’s the middle of the night, you are on a deserted roadway, and your car breaks...",
    category: "Automotive",
    cover: "../posts/post1.svg",
    date: "APRIL 05, 2018",
  },

  {
    id: 2,
    title: "The World's First Supercar that shows the true power of speed",
    desc: "Facilisi etiam dignissim diam quis enim lobortis scelerisque. Ligula ullamcorper malesuada proin libero nunc consequat. Sit amet dictum sit amet. Enim diam vulputate ut pharetra. Aliquet porttitor lacus luctus accumsan tortor posuere ac. Consequat semper viverra nam libero justo laoreet sit amet cursus",
    category: "Automotive",
    cover: "../posts/post2.svg",
    date: "MARCH 15, 2018",
  },
  {
    id: 3,
    title: "The Pros and Cons of Automatic Transmissions",
    desc: "Facilisi etiam dignissim diam quis enim lobortis scelerisque. Ligula ullamcorper malesuada proin libero nunc consequat. Sit amet dictum sit amet. Enim diam vulputate ut pharetra. Aliquet porttitor lacus luctus accumsan tortor posuere ac. Consequat semper viverra nam libero justo laoreet sit amet cursus",
    category: "Sports",
    cover: "../posts/post3.svg",
    date: "MARCH 15, 2018",
  },
  {
    id: 4,
    title: "How to choose the right aftermarket wheels for your car",
    desc: "Facilisi etiam dignissim diam quis enim lobortis scelerisque. Ligula ullamcorper malesuada proin libero nunc consequat. Sit amet dictum sit amet. Enim diam vulputate ut pharetra. Aliquet porttitor lacus luctus accumsan tortor posuere ac. Consequat semper viverra nam libero justo laoreet sit amet cursus",
    category: "Automotive",
    cover: "../posts/post4.svg",
    date: "February 20, 2018",
  },
  
]


export const RAKitFeatures = [
	{
		image: '../products/RAKit/color.svg',
		title: 'Color options',
		desc: 'This long EVA Bag is produced in two colors; black and red'
	},
	{
		image: '../products/RAKit/quality.svg',
		title: 'quality material',
		desc: 'The material is rugged and free from wear and tear.'
	},
	{
		image: '../products/RAKit/sleek.svg',
		title: 'sleek design',
		desc: 'Reflective Triangle, First Aid Kit and a Tyre Pressure Gauge in a sleek designed bag.'
	},
]
export const FEXFeatures = [
	{
		image: '../products/FE/color.svg',
		title: 'Color options',
		desc: 'Our Fire Extinguisher comes in two colors; chrome and red.'
	},
	{
		image: '../products/FE/smart.svg',
		title: 'smart clip mechanism',
		desc: 'Can be held and operated in seconds with ease.'
	},
	{
		image: '../products/FE/sleek.svg',
		title: 'sleek design',
		desc: 'With the reduction of height and diameter, the fire extinguisher is now more sleek and uses less space.'
	},
]
export const FECFeatures = [
	{
		image: '../products/FEC/color.svg',
		title: 'Color options',
		desc: 'This clamp comes in two colors; Red and Black and can be customized.'
	},
	{
		image: '../products/FEC/sturdy.svg',
		title: 'STURDY',
		desc: 'Has a sturdy hold that locks the fire extinguisher in place.'
	},
	{
		image: '../products/FEC/lock.svg',
		title: 'Single handed lock',
		desc: 'Can be held and operated single handedly in seconds with ease.'
	},
]

export const RAKitContent = [
	{
		image: './products/1x.svg',
		title: 'Warning Triangle',
	},
	{
		image: '../products/1x.svg',
		title: 'Tire Pressure Gauge',
	},
	{
		image: '../products/1x.svg',
		title: 'Emergency Blanket 210x130cm',
	},
	{
		image: '../products/1x.svg',
		title: 'Triangular Bandage 136x96x96cm',
	},
	{
		image: '../products/1x.svg',
		title: 'PBT Bandage 5x450cm',
	},
	{
		image: '../products/1x.svg',
		title: 'Alcohol pad',
	},
	{
		image: '../products/1x.svg',
		title: 'Antiseptic Cleaning Wipes',
	},
	{
		image: '../products/5x.svg',
		title: 'Adhesive Plaster',
	},
	{
		image: '../products/1x.svg',
		title: 'Scissors',
	},
]

export const CTKitContent = [
	{
		image: './products/1x.svg',
		title: 'Velcro Bag',
	},
	{
		image: './products/1x.svg',
		title: 'Nose Plier',
	},
	{
		image: './products/1x.svg',
		title: 'Spanner (8” and 10”)',
	},
	{
		image: './products/1x.svg',
		title: 'Spanner (13” and 15”)',
	},
	{
		image: './products/1x.svg',
		title: 'Tyre Gauge',
	},
	{
		image: './products/1x.svg',
		title: 'Screwdriver (Plus and Minus)',
	},
]
export const CTKitGallery = [
	{
		id: 1,
		image: './gallery/CTKit/thumb/1.png',
	},
	{
		id: 2,
		image: './gallery/CTKit/thumb/2.png',
	},
	{
		id: 3,
		image: './gallery/CTKit/thumb/3.png',
	},
	{
		id: 4,
		image: './gallery/CTKit/thumb/4.png',
	},
]
export const CTKitGalleryFull = [
	'./gallery/CTKit/1.jpg',
	'./gallery/CTKit/2.jpg',
	'./gallery/CTKit/3.jpg',
	'./gallery/CTKit/4.jpg'
]
export const FEGallery = [
	{
		id: 1,
		image: './gallery/FE/thumb/1.png',
	},
	{
		id: 2,
		image: './gallery/FE/thumb/2.png',
	},
	{
		id: 3,
		image: './gallery/FE/thumb/3.png',
	},
	{
		id: 4,
		image: './gallery/FE/thumb/4.png',
	},
	{
		id: 5,
		image: './gallery/FE/thumb/5.png',
	},

]
export const FEGalleryFull = [
	'./gallery/FE/1.jpg',
	'./gallery/FE/2.jpg',
	'./gallery/FE/3.jpg',
	'./gallery/FE/4.jpg',
	'./gallery/FE/5.jpg'

]
export const FECGallery = [
	{
		id: 1,
		image: './gallery/FEC/thumb/1.png',
	},
	{
		id: 2,
		image: './gallery/FEC/thumb/2.png',
	},
	{
		id: 3,
		image: './gallery/FEC/thumb/3.png',
	},
	{
		id: 4,
		image: './gallery/FEC/thumb/4.png',
	},
	{
		id: 5,
		image: './gallery/FEC/thumb/5.png',
	},
	{
		id: 6,
		image: './gallery/FEC/thumb/6.png',
	},
]
export const FECGalleryFull = [
	'./gallery/FEC/1.jpg',
	'./gallery/FEC/2.jpg',
	'./gallery/FEC/3.jpg',
	'./gallery/FEC/4.jpg',
	'./gallery/FEC/5.jpg',
	'./gallery/FEC/6.jpg'
]
export const RAKitGallery = [
	{
		id: 1,
		image: './gallery/RAKit/thumb/1.png',
	},
	{
		id: 2,
		image: './gallery/RAKit/thumb/2.png',
	},
	{
		id: 3,
		image: './gallery/RAKit/thumb/3.png',
	},
	{
		id: 4,
		image: './gallery/RAKit/thumb/4.png',
	},
	{
		id: 5,
		image: './gallery/RAKit/thumb/5.png',
	},
	{
		id: 6,
		image: './gallery/RAKit/thumb/6.png',
	},
]
export const RAKitGalleryFull = [
	'./gallery/RAKit/1.jpg',
	'./gallery/RAKit/2.jpg',
	'./gallery/RAKit/3.jpg',
	'./gallery/RAKit/4.jpg',
	'./gallery/RAKit/5.jpg',
	'./gallery/RAKit/6.jpg'
]
export const HomeGallery = [
	{
		id: 1,
		thumb: './gallery/Home/thumb/1.png',
	},
	{
		id: 2,
		thumb: './gallery/Home/thumb/2.png',
	},
	{
		id: 3,
		thumb: './gallery/Home/thumb/3.png',
	},
	{
		id: 4,
		thumb: './gallery/Home/thumb/4.png',
	},
	{
		id: 5,
		thumb: './gallery/Home/thumb/5.png',
	},
	{
		id: 6,
		thumb: './gallery/Home/thumb/6.png',
	},
	{
		id: 7,
		thumb: './gallery/Home/thumb/7.png',
	},
	{
		id: 8,
		thumb: './gallery/Home/thumb/8.png',
	},
	{
		id: 9,
		thumb: './gallery/Home/thumb/9.png',
	},
	{
		id: 10,
		thumb: './gallery/Home/thumb/10.png',
	},
]
export const HomeGalleryFull = [
	'./gallery/Home/1.jpg',
	'./gallery/Home/2.png',
	'./gallery/Home/3.png',
	'./gallery/Home/4.jpg',
	'./gallery/Home/5.jpg',
	'./gallery/Home/7.jpg',
	'./gallery/Home/8.jpg',
	'./gallery/Home/9.jpg',
	'./gallery/Home/10.jpg',
	'./gallery/Home/11.jpg'
]


export const OurTeam = [
	{
		name: 'Hesham Ahmed',
		designation: 'Technical Director',
		thumb: './team/Hesham.png',
		linkedin: 'https://www.linkedin.com/in/hesham-ahmed-5207515b/'
	},
	{
		name: 'Zia Manzoor',
		designation: 'Vice President Sales',
		thumb: './team/Zia.png',
		linkedin: 'https://www.linkedin.com/in/zia-manzoor-60183114/'
	},
	{
		name: 'Saika Alauddin',
		designation: 'Project Manager',
		thumb: './team/Saika.png',
		linkedin: 'https://www.linkedin.com/in/saika-alauddin-08913998'
	},
	{
		name: 'Adnan Ahmed',
		designation: 'Procurement Manager',
		thumb: './team/Adnan.png',
		linkedin: 'https://www.linkedin.com/in/adnan-shakil-7483a638'
	},
	{
		name: 'Nabeel Usman',
		designation: 'Operations Analyst',
		thumb: './team/Nabeel.png',
		linkedin: 'https://www.linkedin.com/in/nabeel-usman-6894a81b0/'
	},
	{
		name: 'Suleman Ali',
		designation: 'Business Development Manager',
		thumb: './team/suleman.jpg',
		linkedin: 'https://www.linkedin.com/in/suleman-ali-71588062/'
	},
	{
		name: 'Daniyal Sabir',
		designation: 'Warehouse Supervisor',
		thumb: './team/danial.jpg',
		linkedin: '#'
	},
	{
		name: 'Dhamendra Ray',
		designation: 'Warehouse Assistant',
		thumb: './team/dhamendar.jpg',
		linkedin: '#'
	},
	{
		name: 'Mohammad Ramshim',
		designation: 'Vehicle Maintenance Supervisor',
		thumb: './team/ramzi.jpg',
		linkedin: '#'
	},
	{
		name: 'Marie Martos',
		designation: 'Accountant',
		thumb: './team/Marie.png',
		linkedin: 'https://www.linkedin.com/in/tita-marie-martos-548334175/'
	},
	{
		name: 'Rolf Jean',
		designation: 'Administrative Assistant',
		thumb: './team/Rolf.png',
		linkedin: 'https://www.linkedin.com/in/rolf-jean-hendrik-deocampo'
	},
	{
		name: 'Areej Ahmed',
		designation: 'Graphic Designer',
		thumb: './team/Areej.png',
		linkedin: 'https://www.linkedin.com/in/areejsahmed/'
	},
	{
		name: 'Muhammad Arif',
		designation: 'Web Developer',
		thumb: './team/Arif.png',
		linkedin: 'http://www.linkedin.com/in/muhammad-arif-saroha'
	},
	{
		name: 'Adeel Usman',
		designation: 'IT Consultant',
		thumb: './team/adeel.png',
		linkedin: 'https://www.linkedin.com/in/adeel-usman-020a0214a/'
	},
	{
		name: 'M Salameh',
		designation: 'IT Support Engineer',
		thumb: './team/Muhammad.png',
		linkedin: 'https://www.linkedin.com/in/mohammed-salameh1993/'
	}
]