import React from "react";
import { Link } from "react-router-dom";
import { category } from "../../data/data";
import Styles from './category.module.css';

const RecentCategories = () => {
    return (
    
            <div className="sideWidget">
                <h4>Categories</h4>
                <ul className={ Styles.recentCats }>
                    {category.map((item) => (
                        
                        <li><Link to={item.id}>{item.category}</Link></li>
                    ))}

                </ul>
            </div>
        
    )

}
export default RecentCategories;