import React, {useState, useRef, useEffect} from "react";
import { FaBars } from "react-icons/fa";
import Navbar from "./Navbar";


const Responsive = () => {
    const [navbarOpen, setNavbarOpen] = useState(false)
    const ref = useRef()
    useEffect(() => {
        const handler = (event) => {
        if (
            navbarOpen &&
            ref.current &&
            !ref.current.contains(event.target)
        ) {
           setNavbarOpen(false);
        }
        };
        document.addEventListener('mousedown', handler);
        return () => {
        // Cleanup the event listener
        document.removeEventListener('mousedown', handler);
        };
    }, [navbarOpen]);

    return (
        <div className="resp-header">
            <div className="resp-logo">
                <a href="/" title=""><img src="./assets/logo.svg" alt="" /></a>
            </div>
            <div className={`resp-menu${navbarOpen ? ' show' : ' '}`}>
                <a onClick={() => setNavbarOpen((prev) => !prev)}  className="toggle-menu" href="#" title=""><FaBars /></a>
                <ul>
                    <Navbar />
                </ul>
            </div>
        </div>
    )

}
export default Responsive;