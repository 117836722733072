import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const Errorstyle = {
    pageNotFound : {
        padding: '80px 0',
        textAlign:'center',
        h1 : {
            color:'#e8e8e8',
        },
        h3 : {
            color:'#fff',
            padding: "15px 0 25px 0"
        }
    }
}
const NotFound = () => {
    return(
        <>
        <Container>
            <Row>
                <Col>
                <div className="page_not_found" style={Errorstyle.pageNotFound}>
                    <h1 style={Errorstyle.pageNotFound.h1}>Oops!</h1>
                    <h3 style={Errorstyle.pageNotFound.h3}>Page Not Found!</h3>
                    <Link to="/" className="btn-theme">Go Back To Home</Link>
                </div>
                </Col>
            </Row>
        </Container>
        </>
    );
}
export default NotFound;