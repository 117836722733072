import React from "react";
import { NavLink } from "react-router-dom";
import Styles from '../Widgets/menu.module.css';

const FooterMenu = ()  => {
    return (
        <>
            <div className="widget-title">
                <h4>About Us</h4>
            </div>
            <div className={ Styles.footertMenu}>
                <ul>

                    <li><NavLink to="/about">About Us</NavLink></li>
                    <li><NavLink to="/products">Products</NavLink></li>
                    <li><NavLink to="/privacy">Privacy Policy</NavLink></li>
                    <li><NavLink to="/terms">Terms & Conditions</NavLink></li>
                </ul>
            </div>
        </>
    );
}

export default FooterMenu;