import React, { useRef } from 'react';
import Slider from "react-slick";
import GalleryImage from '../Products/GalleryImage';
import Styles from '../css/team.module.css';
import {OurTeam} from '../../data/data';
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { Container, Row, Col } from "react-bootstrap";

const Team = () => {
    const slider = useRef(); 
    const next = () => {
        slider.current.slickNext();
    };
    const previous = () => {
        slider.current.slickPrev();
    };
    var settings = {
        dots: false,
        arrows: false,
        autoplay: true,
        infinite: true,
        slidesToShow: 4,
        touchMove: false,
        slidesToScroll: 1,
        responsive: [
            {
             breakpoint: 1024,
             settings: {
               slidesToShow: 2,
               slidesToScroll: 1,
               infinite: true,
               dots: true,
               touchMove: false,
             },
           },
           {
             breakpoint: 768,
             settings: {
               slidesToShow: 2,
               slidesToScroll: 1,
               touchMove: true,
             },
           },
           {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              touchMove: true,
            },
          },
        ],
    };
    return(
        <div className="gap r_top">
            <Container>
                <Row>
                    <Col md={12}>
                        <div className={ Styles.teamCarousel }>
                        <h2 className="white-color uppercase"> Our Team </h2>
                        <button className={ Styles.slickArrows + ' ' + Styles.prev } onClick={previous}> <FaAngleLeft/> </button>
                        <Slider ref={(c) => (slider.current = c)} {...settings}>
                            {OurTeam.map((item, index) => (
                                <div key={index} className={ Styles.teamCard }>
                                    <a href={ item.linkedin } title={ item.name } target="_blank" rel="noreferrer">
                                        <GalleryImage thumb = { item.thumb } imgHeight="320px" />
                                    </a>
                                    <div className={ Styles.teamDetail }>
                                        <h4>{ item.name }</h4>
                                        <span>{ item.designation }</span>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                        <button className={ Styles.slickArrows + ' ' + Styles.next } onClick={next}> <FaAngleRight/> </button>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
        
    )
}

export default Team;