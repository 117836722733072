import React from "react";
import { NavLink } from "react-router-dom";
import Styles from '../Widgets/menu.module.css';

const FooterProdLinks = ()  => {
    return (
        <>
            <div className="widget-title">
                <h4>Safety Products</h4>
            </div>
            <div className={ Styles.footertMenu}>
                <ul>
                    <li><NavLink to="/fire-Extinguisher">Fire Extinguisher</NavLink></li>
                    <li><NavLink to="/fire-Extinguisher-clamp">Fire Extinguisher Clamp</NavLink></li>
                    <li><NavLink to="/roadside-assitance-kit">Roadside Assistance Kit</NavLink></li>
                    <li><NavLink to="/car-tool-kit">Car Tool Kit</NavLink></li>
                </ul>
            </div>
        </>
    );
}

export default FooterProdLinks;