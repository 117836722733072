import React from "react";
import Styles from '../Widgets/about.module.css';

const About = () => {
    return(
        <>
            <div className={ Styles.about_widget}>
                <a href="/" title="">
                    <img src="../assets/logo-white.svg" alt="" />
                </a>
                <p>Micronisus is a technology based automotive solutions provider.</p>
                <span>Support center</span>
                <strong>(+971) 4 566 9827</strong>
            </div>
        </>
    );
}
export default About; 