import React, {useState, useEffect} from "react";
import emailjs from 'emailjs-com';
import Styles from '../Widgets/subscribe.module.css';

const Subscribe = () => {
    const intialValues = { to_name:'info@micronisus.ae',  your_email: "" };
    const [formValues, setFormValues] = useState(intialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    //input change handler
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    //form submission handler
    const handleSubmit = (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setFormErrors(validate(formValues));
    };

    //form validation handler
    const validate = (values) => {
        let errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

        if (!values.your_email) {
            errors.your_email = "Email cannot be blank";
        } else if (!regex.test(values.your_email)) {
            errors.your_email = "Invalid email format";
        }
        return errors;
    };

    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmitting) {
        sendEmail();
    }
    }, [formErrors]);

    function sendEmail() {

        emailjs.send('service_c0deoo3', 'template_3h8x14i', formValues, 'MbY-7wfdtcZiguHEH')
        .then((result) => {
            window.location.reload()  //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior) 
        }, (error) => {
            console.log(error.text);
        });
    }

    return(
        <>
            <div className="widget-title">
                <h4>Subscribe to Newsletter</h4>
            </div>
            <div className={Styles.subscribeForm}>
                <form onSubmit={handleSubmit} noValidate>
                    <label>Your Email</label>
                    <input 
                        name="your_email" 
                        type="email" 
                        placeholder="Enter Your Email" 
                        className={formErrors.your_email && "input__error"}
                        value={formValues.your_email}
                        onChange={handleChange}
                    />
                    {formErrors.your_email && (
                        <span className="form__error">{formErrors.your_email}</span>
                    )}
                    <button type="submit" className= {(Object.keys(formErrors).length === 0 && isSubmitting) ? "btn-theme filled green" : "btn-theme filled" } >
                        {
                        (Object.keys(formErrors).length === 0 && isSubmitting) ? 
                            "Submitted successfully!"
                         : 
                            'Subscribe'
                        }
                    </button>
                </form>
            </div>
        </>
    );
}
export default Subscribe;