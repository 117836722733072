import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Styles from '../css/fancyProcess.module.css'


const FancyProcess = () => {
    return (
        <div className={Styles.fancy_processes}>
            <Container>
                <Row>
                    <Col md={12}>
                        <h2 className="white-color uppercase">Our PROCESS</h2>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <div className={ Styles.fancy_process }>
                            <img src="./assets/fancy_process4.svg" alt="" />
                            <h4>DESIGN</h4>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className={ Styles.fancy_process }>
                            <img src="./assets/fancy_process1.svg" alt="" />
                            <h4>3D SCANNING</h4>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className={ Styles.fancy_process }>
                            <img src="./assets/fancy_process3.svg" alt="" />
                            <h4>3D MODELING</h4>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className={ Styles.fancy_process }>
                            <img src="./assets/fancy_process2.svg" alt="" />
                            <h4>3D PRINTING</h4>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className={ Styles.fancy_process }>
                            <img src="./assets/fancy_process6.svg" alt="" />
                            <h4>REVERSE ENGINEERING</h4>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className={ Styles.fancy_process }>
                            <img src="./assets/fancy_process5.svg" alt="" />
                            <h4>QUALITY</h4>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
export default FancyProcess;