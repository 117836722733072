import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import Banner from "../Global/Banner";
import Styles from '../css/listings.module.css';

const Listings = () => {

    return(
       <>
            <Banner />
            <Container>
                <Row>
                    <Col md={12}>
                        <div className={ Styles.topImg}>
                            <img src="../products/other_products.svg" alt="" />
                        </div>
                    </Col>
                </Row>

                <Row className="gx-5">
                    <Col md={12}>
                        <div className={ Styles.prodTitle}>
                            <h3>Products</h3>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className={Styles.prodItem}>
                            <img src="../products/others/surface_Illumination.svg" alt="" />
                            <a href="#" title="">SURFACE ILLUMINATION</a>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className={Styles.prodItem}>
                            <img src="../products/others/decals.svg" alt="" />
                            <a href="#" title="">DECALS</a>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className={Styles.prodItem}>
                            <img src="../products/others/soft_emblem.svg" alt="" />
                            <a href="#" title="">3D SOFT EMBLEMS</a>
                        </div>
                    </Col>

                    <Col md={4}>
                        <div className={Styles.prodItem}>
                            <img src="../products/others/sill_plates.svg" alt="" />
                            <a href="#" title="">WIRELESS ILLUMINATED SILL PLATES</a>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className={Styles.prodItem}>
                            <img src="../products/others/mirror_garnish.svg" alt="" />
                            <a href="#" title="">MIRROR GARNISH</a>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className={Styles.prodItem}>
                            <img src="../products/others/shark_finn.svg" alt="" />
                            <a href="#" title="">SHARK FINS</a>
                        </div>
                    </Col>

                    <Col md={4}>
                        <div className={Styles.prodItem}>
                            <img src="../products/others/spoiler.svg" alt="" />
                            <a href="#" title="">SPOILERS</a>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className={Styles.prodItem}>
                            <img src="../products/others/styling_components.svg" alt="" />
                            <a href="#" title="">STYLING COMPONENTS</a>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className={Styles.prodItem}>
                            <img src="../products/others/chrome_accents.svg" alt="" />
                            <a href="#" title="">CHROME ACCENTS</a>
                        </div>
                    </Col>

                    <Col md={4}>
                        <div className={Styles.prodItem}>
                            <img src="../products/others/floormat_Illumination.svg" alt="" />
                            <a href="#" title="">FLOORMAT ILLUMINATION</a>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className={Styles.prodItem}>
                            <img src="../products/others/surface_decor.svg" alt="" />
                            <a href="#" title="">SURFACE DECORATION</a>
                        </div>
                    </Col> 
                </Row>
            </Container>
            
       </>
    );

}
export default Listings;