import React, { useRef, useState } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import Banner from "../Global/Banner";
import Slider from "react-slick";
import FsLightbox from "fslightbox-react";
import GalleryImage from '../Products/GalleryImage';
import { FaSearchPlus, FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { FEGalleryFull, FEXFeatures, FEGallery } from "../../data/data";
import Styles from '../css/singleProduct.module.css';


const FileExtinguisher  = () => {
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [lightboxIndex, setLightboxIndex] = useState(0);
    const openLightbox = (index) => {
        setLightboxIndex(index);
        setLightboxOpen(!lightboxOpen);
    };

    const slider = useRef();
    const next = () => {
        slider.current.slickNext();
    };
    const previous = () => {
        slider.current.slickPrev();
    };
    var settings = {
        dots: false,
        arrows: false,
        autoplay: true,
        infinite: true,
        centerPadding: '50px',
        slidesToShow: 4,
        touchMove: false,
        slidesToScroll: 1,
        responsive: [
            {
             breakpoint: 1024,
             settings: {
               slidesToShow: 3,
               slidesToScroll: 1,
               infinite: true,
               dots: true,
             },
           },
           {
             breakpoint: 768,
             settings: {
               slidesToShow: 2,
               slidesToScroll: 1,
               touchMove: true,
             },
           },
           {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              touchMove: true,
            },
          },
        ],
    };
    return (
        <>
            <Banner />
            <Container>
                <Row className="align-center gx-5 gap">
                    <Col md={8}>
                        <div className={ Styles.singleContent}>
                            <h2>FIRE EXTINGUISHER   </h2>
                            <p>Fire extinguisher is the most essential safety product in your car. We have redesigned it to best fit the need in an automobile. The best feature it boasts is the smart clip mechanism used to operate. 
We designed it in two colors; red and chrome. The chrome blends well with the luxury ambiance of high end automobiles. The red one being used more commonly for general automobiles.
                            </p>
                            <ul>
                                <li>Size: Φ90*285mm</li>
                                <li>Weight: 1.63kgs</li>
                                <li>Color: Red/Chrome</li>
                                <li>Capacity: 1KG</li>
                                <li>Effective Discharge Time: 10s</li>
                                <li>Effective Discharge Range: 3s</li>
                                <li>Temperature: -30℃~+60℃</li>
                            </ul>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className={ Styles.singleContent}>
                            <GalleryImage thumb = "../products/single-fire-extinguisher.svg" imgHeight="410" />
                        </div>
                    </Col>
                </Row>
                <div className={ Styles.prodFeatures}>
                    <h2 className='white-color uppercase'>WHY CHOOSE OUR PRODUCT?</h2>
                    <Row>
                    {FEXFeatures.map((item) => (
                        <Col md={4}>
                            <div className={ Styles.featBox }>
                                <img src={ item.image } alt="" />
                                <h5>{ item.title }</h5>
                                <p>{ item.desc}</p>
                            </div>
                        </Col>
                        ))}
                    </Row>
                </div>
                <div className={ Styles.galleries}>
                    <h2 className='white-color uppercase'>Gallery</h2>
                    <button className={ Styles.slickArrows + ' ' + Styles.prev } onClick={previous}> <FaAngleLeft/> </button>
                    <Slider ref={(c) => (slider.current = c)} {...settings}>
                        {FEGallery.map((item) => (
                        <div className={Styles.galleryItem}>
                            <GalleryImage thumb = { item.image } imgHeight="320" />
                            <a onClick={() => openLightbox(item.id)}><FaSearchPlus /></a>
                        </div>
                        ))}
                    </Slider>
                    <button className={ Styles.slickArrows + ' ' + Styles.next } onClick={next}> <FaAngleRight/> </button>
                    <FsLightbox
                        toggler={lightboxOpen}
                        slide={lightboxIndex}
                        sources={FEGalleryFull}
                    />
                </div>
            </Container>
        </>
    );
}
export default FileExtinguisher;
