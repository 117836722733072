import React, { useState } from "react";
import {Container, Row, Col} from 'react-bootstrap';
import Responsive from "./Responsive";
import Navbar from "./Navbar";

const Header = () => {
    const [fix, setFix] = useState()
    function setFixed() {
        if (window.scrollY >= 100) {
            setFix(true)
        }else {
            setFix(false)
        }
    }
    window.addEventListener("scroll", setFixed)

    return(
        <>
            <header className={fix ? 'main_header fixed' : 'main_header'} >
            <Container> 
                <Row className="align-items-center">
                    <Col md={3}>
                        <div className="site_logo">
                            <a href="/" title=""><img src="./assets/logo.svg" alt="" /></a>
                        </div>
                    </Col>
                    <Col md={7}>
                    
                        <nav>
                            <ul>
                                <Navbar />
                            </ul>
                        </nav>
                    </Col>
                    <Col md={2}>
                        <a href="tel:+97145669827" title="" className="btn-theme border">(+971) 4 566 9827 </a>
                    </Col>
                </Row>
            </Container>
            </header>
            <Responsive />
        </>
    );

} 
export default Header;