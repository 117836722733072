import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Styles from '../css/workingProducts.module.css';

const WorkingProducts = () => {
    return (
        <div className="gap r_top">  
            <Container>
                <Row>
                    <Col md={4}>
                        <div className={ Styles.sectHeading}>
                            <h2>PRODUCTS IN THE <br />WORKS</h2>
                            <p>Get ready for some exciting new features and upgrades for your vehicle! At Micronisus Automotive, we're always working to bring you the latest and greatest in automotive technology, and we're thrilled to announce some new products that will be coming soon.</p>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className={Styles.workingCard}>
                            <h6>
                                <span>01</span>
                                Speed LIMITER
                            </h6>
                            <p>Our new speed limiter can help you stay within the speed limit and avoid speeding tickets.</p>
                        </div>
                        <div className={Styles.workingCard}>
                            <h6>
                                <span>03</span>
                                BLUETOOTH HANDSFREE KIT
                            </h6>
                            <p>Our new Bluetooth handsfree kit allows you to make and receive calls while driving without taking your hands off the wheel.</p>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className={Styles.workingCard}>
                            <h6>
                                <span>02</span>
                                PARKING SENSOR
                            </h6>
                            <p>Our new parking sensors are designed to help you park your vehicle more safely and easily.</p>
                        </div>
                        <div className={Styles.workingCard}>
                            <h6>
                                <span>04</span>
                                Infotainment
                            </h6>
                            <p>Our new infotainment system offers a range of features that will enhance your driving experience.</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div> 
    )
}
export default WorkingProducts; 