import React, {useState, useEffect} from "react";
import emailjs from 'emailjs-com';
import { Container, Row, Col } from "react-bootstrap";
import { FaCheck } from "react-icons/fa";

import Styles from '../css/Contactform.module.css';

const ContactForm = () => {
    const intialValues = { to_name:'info@micronisus.ae', your_name: '', phone_number: '', your_email: "", selected_product: '',  your_message: "" };
    const [formValues, setFormValues] = useState(intialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    //input change handler
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    //form submission handler
    const handleSubmit = (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setFormErrors(validate(formValues));
    };

    //form validation handler
    const validate = (values) => {
        let errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        const phoneregix = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
        const nameregix = /^[a-zA-Z ]{2,30}$/;

        if (!values.your_email) {
            errors.your_email = "Email cannot be blank";
        } else if (!regex.test(values.your_email)) {
            errors.your_email = "Invalid email format";
        }

        if (!values.your_name) {
            errors.your_name = "Name cannot be blank";
        }else if(!nameregix.test(values.your_name) ) {
            errors.your_name = "Name should be valid!";
        }

        if (!values.phone_number) {
            errors.phone_number = "Phone number Cannot be blank";
        }else if(!phoneregix.test(values.phone_number) ) {
            errors.phone_number = "Phone number is not valid";
        }
        return errors;
    };

    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmitting) {
        sendEmail();
    }
    }, [formErrors]);

    function sendEmail() {
        emailjs.send('service_c0deoo3', 'template_jdm4vir', formValues, 'MbY-7wfdtcZiguHEH')
        .then((result) => {
            window.location.reload()  //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior) 
        }, (error) => {
            console.log(error.text);
        });
    }

    return (
        <Container>
            <Row id="query-form">
                <Col md={6}>
                    <h3 className="white-color uppercase">SUBMIT YOUR QUERY</h3>
                    <p className="white-color">If you have any questions, comments, or concerns regarding our products or services, we encourage you to submit your query through our online form. Our team will respond as soon as possible to provide you with the assistance you need.</p>
                </Col>
            </Row>
            <form onSubmit={handleSubmit} noValidate>
                <Row>
                    <Col md={6}>
                        <div className={ Styles.field_option }>
                            <label>Your Name</label>
                            <input
                                name="your_name" 
                                type="text"   
                                placeholder="Enter Your Name"
                                className={formErrors.your_name && "input__error"}
                                value={formValues.your_name}
                                onChange={handleChange}
                            />
                        </div>
                        {formErrors.your_name && (
                            <span className="form__error">{formErrors.your_name}</span>
                        )} 
                    </Col>
                    <Col md={6}>
                        <div className={ Styles.field_option }>
                            <label>Your Phone</label>
                            <input 
                                name="phone_number" 
                                type="text" 
                                placeholder="Enter Your Phone"
                                className={formErrors.phone_number && "input__error"}
                                value={formValues.phone_number}
                                onChange={handleChange}
                            />
                            {formErrors.phone_number && (
                                <span className="form__error">{formErrors.phone_number}</span>
                            )} 
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className={ Styles.field_option }>
                            <label>Select Product</label>
                            <select 
                                value={formValues.selected_product}
                                onChange={handleChange}  
                                name="selected_product"
                            >
                                <option value='none'>select Product</option>
                                <option value='fex'>Fire Extinguisher</option>
                                <option value='fexc'>Fire Extinguisher Clamp</option>
                                <option value='ctkit'>Car Tool Kit</option>
                                <option value='rakit'>Roadside Assistance Kit</option>
                            </select>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className={ Styles.field_option }>
                            <label>Your Email</label>
                            <input 
                                name="your_email" 
                                type="email" 
                                placeholder="Your Email" 
                                className={formErrors.your_email && "input__error"}
                                value={formValues.your_email}
                                onChange={handleChange}
                            />
                            {formErrors.your_email && (
                                <span className="form__error">{formErrors.your_email}</span>
                            )}
                        </div>
                    </Col>
                    <Col md={12}>
                        <div className={ Styles.field_option }>
                            <label>Your Message</label>
                            <textarea  
                                name="your_message" 
                                placeholder="Describe Your Problem"
                                value={formValues.your_message}
                                onChange={handleChange}
                            >
                            </textarea>
                        </div>
                        <button type="submit" className="btn-theme filled">Send</button>
                        {Object.keys(formErrors).length === 0 && isSubmitting && (
                            <span className="success-msg"><FaCheck/>Form submitted successfully</span>
                        )}
                    </Col>
                </Row>
                </form>
        </Container>
    );
}
export default ContactForm;