import React, {useState} from 'react';

import Header from './components/Header';
import Footer from './components/Footer';
import  {Route, Routes } from 'react-router-dom';
import { FaArrowUp } from 'react-icons/fa';
/* Import App Components */
import Home from './components/Home';
import About from './components/About';
import Blog from './components/Blog';
import Products from './components/Products';
import Contact from './components/Contact';
import NotFound from './components/NotFound';
import PrivacyPolicy from './components/PrivacyPolicy';
import Terms from './components/Terms';
import Listings from './components/Products/Listings';

import Assistancekit from './components/Products/Assistancekit';
import CarToolKit from './components/Products/CarToolKit';
import FireExtinguisher from './components/Products/FireExtinguisher';
import FireExtinguisherClamp from './components/Products/FireExtinguisherClamp';

/* Import Global Style */
import './main.css';
import 'bootstrap/dist/css/bootstrap-grid.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";




function App() {
  const [visible, setVisible] = useState(false)
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300){
      setVisible(true)
    } else if (scrolled <= 300) {
      setVisible(false)
    }
  };
  const scrollToTop = () =>{
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
    });
  };
  window.addEventListener('scroll', toggleVisible);

  return(
    <>
      <Header />
      <Routes>
          <Route exact path="/" element={ <Home /> } />
          <Route path="/about" element={ <About /> } />
          <Route path="/products" element={ <Products /> } /> 
          <Route path="/contact" element={ <Contact /> } />
          <Route path="/blog" element={ <Blog /> } />
          <Route path="/privacy" element={ <PrivacyPolicy /> } />
          <Route path="/terms" element={ <Terms /> } />

          <Route path="/other-products" element = { <Listings /> } />

          <Route path="/roadside-assitance-kit" element = { <Assistancekit /> } />
          <Route path="/car-tool-kit" element = { <CarToolKit /> } />
          <Route path="/fire-extinguisher" element = { <FireExtinguisher /> } />
          <Route path="/fire-extinguisher-clamp" element = { <FireExtinguisherClamp /> } />
          


          <Route path="*" element={ <NotFound /> } />
      </Routes>
      <Footer />
      <button className='scroll__top' style={{display: visible ? 'inline-block' : 'none'}} onClick={scrollToTop}><FaArrowUp  /></button>
          
    </>
  );
}
export default App;